.apiKeyContainerInner {
  width: 80% !important;
}

.apiKeyContainerOuter {
  display: flex;
  justify-content: center;
}

.centerContainerOuter{
  display: flex;
  justify-content: center;
}

.no-border{
  border-style: none;
}