// @import '../../theme/colors.ts';
@import "../../styles/globalStyles.scss";

.container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100vh; /* Set a height so that the vertical centering works */
  margin: 0 auto; /* This will center the div horizontally within its parent container */

  padding: 1rem; //!!!!!!!!!!!!!!

  @media (max-width: 768px) {
    width: 100%; /* Take full width on mobile */
    padding: 1rem; /* Add padding on mobile */
  }
}
.login-btn-provider {
  color: $color-abk-green;
}

.login-btn-provider:hover {
  color: $color-abk-green;
  text-decoration: none;
}
.linkBtn {
  color: white;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkBtn:hover {
  color: white;
  text-decoration: none;
}

.login-btn {
  width: 100%;
  margin: 2em 0;
  position: relative;
  display: inline-block;
  overflow: hidden;
  padding-top: 30px !important;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.containerContent {
  background-color: white;

  width: 90%; 
  max-width: 45em; 
  height: auto; 
  padding: 2rem;
  border: 2px solid $color-abk-green;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
    height: auto; /* Adjust height to auto on mobile */
    max-width: none;
    padding: 1rem;
    border: none;
    box-shadow: none;
  }
}

.container-padding {
  flex: 1;
  padding: 0 3em 2em;

  @media (max-width: 768px) {
    padding: 0 1.5em 1em;
  }
}

.login-background {
  // background-color: $color-abk-green;
  width: 100%;
}

.space-between-container {
  display: flex;
  justify-content: space-between;
}

.copyright-container {
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: center; /* Center the text horizontally */

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 1em;
    padding-bottom: 1em;
    position: relative;
  }
}

.copyright-container a {
  text-decoration: underline;
  color: $color-abk-green;
}

.redirect-text {
  display: inline-block;
  padding-right: 1em;
}

// .k-list{
//     background: blue;
//   }
.k-selected {
  color: $color-abk-green !important;
}
// .k-list-item-text{
//     color: yellow;
// }

.login-logo {
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    // display: none;
  }
}

.logo-image {
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    width: 100%; /* Adjust this value as needed to make the logo smaller */
  }
}

.welcome-text {
  font-size: 1.6em;
  color: $color-abk-green;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
  // white-space: pre-line;
}
.k-checkbox {
  border-color: $color-abk-green;
}
.k-checkbox:checked,
.k-checkbox.k-checked {
  border-color: $color-abk-green;
  color: white;
  background-color: $color-abk-green;
}

.checkbox-grey {
  color: $color-abk-grey;
}

.tenant-selection-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 1em;
  margin-bottom: 1em;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
}

.right-align-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;

  @media (max-width: 768px) {
    justify-content: center;
  }
}

.header-row {
  height: 3em;
  width: 100%;
}

.more-btn {
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: right;
  }
}

.microsoft-login-button {
  width: 60%;
  margin: 2rem auto;

  @media (max-width: 768px) {
    width: 80%;
    margin: 1rem auto;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
