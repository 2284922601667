$portal-font-family: "Roboto", "Helvetica", "Arial", sans-serif;
$color-abk-green: #888e02;
$color-abk-grey: #7e8484;

.h1-portal {
  font-weight: 400;
  color: $color-abk-green;
  line-height: 1.334;
  letter-spacing: 0em;
  font-size: 1.5rem;
  font-family: $portal-font-family;
}

.portal-normal-text {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: justify;
  font-family: $portal-font-family;
}

.portal-simple-text-padding {
  padding-top: 1em;
  padding-bottom: 1em;
}

.portal-primary-btn {
  background-color: $color-abk-green !important;
  border-color: $color-abk-green !important;
  color: white;
}

.portal-primary-btn:hover {
  color: white;
  text-decoration: none;
}

.portal-div-to-right {
  text-align: right;
}

.portal-div-to-center {
  text-align: center;
}

.portal-link {
  color: $color-abk-green;
}

.center-text {
  text-align: center;
}

.portal-gray-text-color {
  color: $color-abk-grey;
}

.portal-button-light {
  background-color: white !important;
  border-color: $color-abk-green !important;
  color: $color-abk-green !important;
}

.portal-button-green {
  background-color: $color-abk-green !important;
  border-color: $color-abk-green !important;
  color: white !important;
}

#tenant-selection-dropdown {
  border-color: $color-abk-grey !important;
}

.portal-small-margin-top {
  margin-top: 3rem;
}

.k-notification {
  margin-bottom: 0.5rem !important;
}

.k-form-error {
  white-space: pre;
}

.k-input.k-input-solid {
  border-width: 0 0 1px;
}

.k-input {
  padding-block: 0px !important;
  padding-inline: 0px !important;
}
