// Compatible with @progress/kendo-theme-fluent v.6.4.0

:root {
  --tb-kendo-border-radius-md: 0.125rem;
  --tb-kendo-primary-10: #F3EC72;
  --tb-kendo-primary-20: #F3EB59;
  --tb-kendo-primary-30: #F3EA3F;
  --tb-kendo-primary-40: #F4ED25;
  --tb-kendo-primary-50: #F5ED0A;
  --tb-kendo-primary-60: #DFD707;
  --tb-kendo-primary-70: #C8C504;
  --tb-kendo-primary-80: #B1AE02;
  --tb-kendo-primary-90: #999600;
  --tb-kendo-primary-100: #999900;
  --tb-kendo-primary-110: #969900;
  --tb-kendo-primary-120: #7D8000;
  --tb-kendo-primary-130: #646600;
  --tb-kendo-primary-140: #4A4D00;
  --tb-kendo-primary-150: #313300;
  --tb-kendo-primary-160: #191A00;
  --tb-kendo-primary-170: #000000;
  --tb-kendo-primary-180: #000000;
  --tb-kendo-primary-190: #000000;
  --tb-kendo-primary-200: #000000;
  --tb-kendo-primary-210: #000000;
  --tb-kendo-primary-220: #000000;
  --tb-kendo-neutral-10: #FFFFFF;
  --tb-kendo-neutral-20: #F2F2F2;
  --tb-kendo-neutral-30: #E6E6E6;
  --tb-kendo-neutral-40: #D9D9D9;
  --tb-kendo-neutral-50: #CCCCCC;
  --tb-kendo-neutral-60: #BFBFBF;
  --tb-kendo-neutral-70: #B3B3B3;
  --tb-kendo-neutral-80: #A6A6A6;
  --tb-kendo-neutral-90: #999999;
  --tb-kendo-neutral-100: #999999;
  --tb-kendo-neutral-110: #999999;
  --tb-kendo-neutral-120: #8F8A8A;
  --tb-kendo-neutral-130: #857B7A;
  --tb-kendo-neutral-140: #7A6C6C;
  --tb-kendo-neutral-150: #6E5E5E;
  --tb-kendo-neutral-160: #625150;
  --tb-kendo-neutral-170: #564443;
  --tb-kendo-neutral-180: #493837;
  --tb-kendo-neutral-190: #3B2C2B;
  --tb-kendo-neutral-200: #2D201F;
  --tb-kendo-neutral-210: #1F1514;
  --tb-kendo-neutral-220: #100A0A;
  --tb-kendo-tertiary-100: #99CCCC;
  --tb-kendo-error-100: #990066;
  --tb-kendo-success-100: #999900;
  --tb-kendo-warning-100: #FFCC66;
  --tb-kendo-info-100: #336666;
  --tb-kendo-body-bg: #ffffff;
  --tb-kendo-series-a-100: #99CCCC;
  --tb-kendo-series-b-100: #e74856;
  --tb-kendo-series-c-100: #FFCC66;
  --tb-kendo-series-d-100: #336666;
  --tb-kendo-series-e-100: #990066;
  --tb-kendo-series-f-100: #999900;
  --tb-kendo-box-shadow-depth-1: 0px 1.6px 3.6px 0 #00000022, 0px 0.3px 0.9px 0 #0000001c;
  --tb-kendo-box-shadow-depth-2: 0px 3.2px 7.2px 0 #00000022, 0px 0.6px 1.8px 0 #0000001c;
  --tb-kendo-box-shadow-depth-3: 0px 6.4px 14.4px 0 #00000022, 0px 1.2px 3.6px 0 #0000001c;
  --tb-kendo-box-shadow-depth-4: 0px 25.6px 57.6px 0 #00000038, 0px 4.8px 14.4px 0 #0000002e;

  --kendo-border-radius-md: var(--tb-kendo-border-radius-md);
  --kendo-primary-10: var(--tb-kendo-primary-10);
  --kendo-primary-20: var(--tb-kendo-primary-20);
  --kendo-primary-30: var(--tb-kendo-primary-30);
  --kendo-primary-40: var(--tb-kendo-primary-40);
  --kendo-primary-50: var(--tb-kendo-primary-50);
  --kendo-primary-60: var(--tb-kendo-primary-60);
  --kendo-primary-70: var(--tb-kendo-primary-70);
  --kendo-primary-80: var(--tb-kendo-primary-80);
  --kendo-primary-90: var(--tb-kendo-primary-90);
  --kendo-primary-100: var(--tb-kendo-primary-100);
  --kendo-primary-110: var(--tb-kendo-primary-110);
  --kendo-primary-120: var(--tb-kendo-primary-120);
  --kendo-primary-130: var(--tb-kendo-primary-130);
  --kendo-primary-140: var(--tb-kendo-primary-140);
  --kendo-primary-150: var(--tb-kendo-primary-150);
  --kendo-primary-160: var(--tb-kendo-primary-160);
  --kendo-primary-170: var(--tb-kendo-primary-170);
  --kendo-primary-180: var(--tb-kendo-primary-180);
  --kendo-primary-190: var(--tb-kendo-primary-190);
  --kendo-primary-200: var(--tb-kendo-primary-200);
  --kendo-primary-210: var(--tb-kendo-primary-210);
  --kendo-primary-220: var(--tb-kendo-primary-220);
  --kendo-neutral-10: var(--tb-kendo-neutral-10);
  --kendo-neutral-20: var(--tb-kendo-neutral-20);
  --kendo-neutral-30: var(--tb-kendo-neutral-30);
  --kendo-neutral-40: var(--tb-kendo-neutral-40);
  --kendo-neutral-50: var(--tb-kendo-neutral-50);
  --kendo-neutral-60: var(--tb-kendo-neutral-60);
  --kendo-neutral-70: var(--tb-kendo-neutral-70);
  --kendo-neutral-80: var(--tb-kendo-neutral-80);
  --kendo-neutral-90: var(--tb-kendo-neutral-90);
  --kendo-neutral-100: var(--tb-kendo-neutral-100);
  --kendo-neutral-110: var(--tb-kendo-neutral-110);
  --kendo-neutral-120: var(--tb-kendo-neutral-120);
  --kendo-neutral-130: var(--tb-kendo-neutral-130);
  --kendo-neutral-140: var(--tb-kendo-neutral-140);
  --kendo-neutral-150: var(--tb-kendo-neutral-150);
  --kendo-neutral-160: var(--tb-kendo-neutral-160);
  --kendo-neutral-170: var(--tb-kendo-neutral-170);
  --kendo-neutral-180: var(--tb-kendo-neutral-180);
  --kendo-neutral-190: var(--tb-kendo-neutral-190);
  --kendo-neutral-200: var(--tb-kendo-neutral-200);
  --kendo-neutral-210: var(--tb-kendo-neutral-210);
  --kendo-neutral-220: var(--tb-kendo-neutral-220);
  --kendo-tertiary-100: var(--tb-kendo-tertiary-100);
  --kendo-error-100: var(--tb-kendo-error-100);
  --kendo-success-100: var(--tb-kendo-success-100);
  --kendo-warning-100: var(--tb-kendo-warning-100);
  --kendo-info-100: var(--tb-kendo-info-100);
  --kendo-body-bg: var(--tb-kendo-body-bg);
  --kendo-series-a-100: var(--tb-kendo-series-a-100);
  --kendo-series-b-100: var(--tb-kendo-series-b-100);
  --kendo-series-c-100: var(--tb-kendo-series-c-100);
  --kendo-series-d-100: var(--tb-kendo-series-d-100);
  --kendo-series-e-100: var(--tb-kendo-series-e-100);
  --kendo-series-f-100: var(--tb-kendo-series-f-100);
  --kendo-box-shadow-depth-1: var(--tb-kendo-box-shadow-depth-1);
  --kendo-box-shadow-depth-2: var(--tb-kendo-box-shadow-depth-2);
  --kendo-box-shadow-depth-3: var(--tb-kendo-box-shadow-depth-3);
  --kendo-box-shadow-depth-4: var(--tb-kendo-box-shadow-depth-4);

  --kendo-font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  --kendo-font-size: 14px;
  --kendo-font-weight: 400;
  --kendo-line-height: 1.4285714286;
}

$tb-typography: (
  kendo-default-typography: (
      font-family: '"Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif',
      font-size: 14px,
      font-weight: 400,
      line-height: 1.4285714286,
  ),
);

@mixin typography-classes ($typography) {
  @each $selector, $property in $typography {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: #{$propValue};
      }
    }
  }
}

$tb-effects: (
  kendo-box-shadow-depth-1: (
      box-shadow: (0px 1.6px 3.6px 0 #00000022, 0px 0.3px 0.9px 0 #0000001c),  ),
  kendo-box-shadow-depth-2: (
      box-shadow: (0px 3.2px 7.2px 0 #00000022, 0px 0.6px 1.8px 0 #0000001c),  ),
  kendo-box-shadow-depth-3: (
      box-shadow: (0px 6.4px 14.4px 0 #00000022, 0px 1.2px 3.6px 0 #0000001c),  ),
  kendo-box-shadow-depth-4: (
      box-shadow: (0px 25.6px 57.6px 0 #00000038, 0px 4.8px 14.4px 0 #0000002e),  ),
  tb-internal-none-effects: (
      box-shadow: (none), filter: (none), backdrop-filter: (none),  ),
);

@mixin effects-classes ($effects) {
  @each $selector, $property in $effects {
    &-#{$selector} {
      @each $propName, $propValue in $property {
        #{$propName}: $propValue;
      }
    }
  }
}