@import "../../styles/globalStyles.scss";

.container-allDataTable{
    margin: 0 10%;
  }

  .container-verticalSpace{
    padding-top: 4rem;
  }


  .container-tenantForm{
    margin: 0 30%;
    padding: 1.5rem;
    background-color: #f5f5f5;
  }


.table-icon{
  width: 1.5rem;
}

input[readonly] {
  cursor: not-allowed;
}
